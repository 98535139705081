<template>
    <div>
        <van-nav-bar  class="nav_bar_view" left-arrow>
      		<template #left>
        		<img @click="returnPage" style="width: 18px; height: 18px" src="../../../assets/black_return_view_bg.png" alt=""/>
      		</template>
			<template #title>
				<span style="color: black; font-size: 18px">用户昵称</span>
			</template>
    	</van-nav-bar>
        <div class="input_name_view">
            <van-field
                class="field_view"
                v-model.trim="userName"
                placeholder="请输入用户昵称">
            </van-field>
            <div class="close_page_view" @click="clearContent">
                <span>x</span>
            </div>
        </div>
        <van-button class="bottom_confirm_view" round type="info" @click="submitInfo">确定</van-button>
    </div>
</template>

<script>
import {update} from "@/service/api/index.js";
import { Toast } from 'vant';
    export default {
        data(){
            return{
                userName:""
            }
        },
        methods:{
            returnPage(){
				this.$router.go(-1)
			},
            clearContent(){
                this.userName = "";
            },
            submitInfo(){
                if(this.userName === ''){
                    Toast("请输入用户名")
                    return;
                }else {
                    update({
                        name:this.userName,
                    }).then((res) =>{
                        if(res.status == 200){
                            this.returnPage();
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="less" scoped>
.nav_bar_view {
  width: 100%;
  height: 44px;
  background: white;
  color: #00b05a;
}
.input_name_view{
    width: 100%;
    height: 48px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .field_view{
        width: 270px;
        height: 100%;
        background: white;   
    }
    .close_page_view{
        width: 16px;
        height: 16px;
        background: #B7B7B7;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 12px;
        color: white;
        justify-content: center;
        line-height: 16px;
        border-radius: 50%;
        span{
            font-size: 10px;
        }
    }
}
.bottom_confirm_view{
    width: 335px;
    height: 36px;
    background: linear-gradient(90deg, #01B15B 0%, #49D0A2 100%);
    border-radius: 18px;
    margin-top: 20px;
    margin-left: 50%;
    transform: translateX(-50%);
    border: none;
    font-size: 13px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 25px;
}
</style>